<template>
  <div>
    <c-search-box @enter="getDeptList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="부서" name="deptCd" :plantCd="searchParam.plantCd" v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <q-splitter
        v-model="splitterModel"
        :limits="[15, 85]"
        unit="%"
      >
        <template v-slot:before>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-tree-table
              title="부서목록"
              parentProperty="upDeptCd"
              customID="deptCd"
              :columns="gridDept.columns"
              :data="gridDept.data"
              :columnSetting="false"
              :isFullScreen="false"
              :usePaging="false"
              :expandAll="true"
              :filtering="false"
              @rowClick="rowClickDept"
            >
              <!-- 버튼 영역 -->
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn label="검색" icon="search" @btnClicked="getDeptList" />
                </q-btn-group>
              </template>
            </c-tree-table>
          </div>
        </template>
        <template v-slot:after>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-card class="cardClassDetailInfo" :noHeader="true" topClass="topcolor-lightblue">
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <c-table
                    ref="processTable"
                    title="부서별 공정 목록"
                    :columns="gridProcess.columns"
                    :data="gridProcess.data"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :usePaging="false"
                    :expandAll="true"
                    topBorderClass="topcolor-lightblue"
                    gridHeight="350px"
                  >
                  </c-table>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <c-table
                    ref="gridEquip"
                    title="부서별 설비 목록"
                    tableId="gridEquip"
                    :columns="gridEquip.columns"
                    :data="gridEquip.data"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :usePaging="false"
                    :expandAll="true"
                    topBorderClass="topcolor-lightblue"
                    gridHeight="350px"
                    @linkClick="linkEquipClick"
                  >
                  </c-table>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <c-table
                    ref="gridChem"
                    title="부서별 화학자재 목록"
                    tableId="gridChem"
                    :columns="gridChem.columns"
                    :data="gridChem.data"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :usePaging="false"
                    :expandAll="true"
                    topBorderClass="topcolor-lightblue"
                    gridHeight="350px"
                    @linkClick="linkChemClick"
                  >
                  </c-table>
                </div>
                <c-dialog :param="popupOptions"></c-dialog>
              </template>
            </c-card>
          </div>
        </template>
      </q-splitter>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-manage',
  data() {
    return {
      splitterModel: 20,
      listDeptUrl: '',
      listProcessUrl: '',
      listEquipUrl: '',
      listChemUrl: '',
      editable: true,
      searchParam: {
        plantCd: null,
        deptCd: '',
        useFlag: 'Y',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      gridDept: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridProcess: {
        columns: [
          {
            name: 'upProcessName',
            field: 'upProcessName',
            label: '상위공정',
            align: 'left',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'left',
            sortable: true,
          },
          {
            name: 'processCd',
            field: 'processCd',
            label: '공정코드',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridEquip: {
        columns: [
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridChem: {
        columns: [
          {
            name: 'mdmChemMaterialId',
            field: 'mdmChemMaterialId',
            label: '화학자재 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'propertiesStateName',
            field: 'propertiesStateName',
            label: '성상',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'equipment_names',
            field: 'equipment_names',
            label: '관련설비',
            align: 'left',
            sortable: true,
          },
          {
            name: 'existRegulation',
            field: 'existRegulation',
            label: '규제포함',
            align: 'center',
            type: 'color',
            style: 'width:70px',
            sortable: true,
          }
        ],
        data: [],
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listDeptUrl = selectConfig.mdm.dept.list.url;
      this.listProcessUrl = selectConfig.mdm.process.list.url;
      this.listEquipUrl = selectConfig.mdm.equipment.list.url;
      this.listChemUrl = selectConfig.mdm.mam.chem.listreg.url + '/equip';
      this.getDeptList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDeptList() {
      this.getListDept();
      this.reset();
    },
    getListDept() {
      this.$http.url = this.listDeptUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridDept.data = _result.data;
      },);
    },
    rowClickDept(row) {
      this.getProcessList(row.deptCd)
      this.getEquipList(row.deptCd)
      this.getChemList(row.deptCd)
    },
    getProcessList(_deptCd) {
      this.$http.url = this.listProcessUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: _deptCd,
        useFlag: 'Y',
        // processLevelCd: '20',
      };
      this.$http.request((_result) => {
        this.gridProcess.data = _result.data;
      },);
    },
    getEquipList(_deptCd) {
      this.$http.url = this.listEquipUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: _deptCd,
      };
      this.$http.request((_result) => {
        this.gridEquip.data = _result.data;
      },);
    },
    getChemList(_deptCd) {
      this.$http.url = this.listChemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: _deptCd,
      };
      this.$http.request((_result) => {
        this.gridChem.data = _result.data;
      },);
    },
    linkEquipClick(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
      };
      this.popupOptions.target = () => import(`${'../fim/equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkChemClick(row) {
      this.popupOptions.title = '화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: row.mdmChemMaterialId,
      };
      this.popupOptions.target = () => import(`${'../mam/chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    reset() {
      this.rowRemoveSelect();
      this.gridProcess.data = [];
      this.gridEquip.data = [];
      this.gridChem.data = [];
    },
  }
};
</script>
